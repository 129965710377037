import { Box, Button } from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import * as React from "react";
import {
  LicenseKeyRejoyceSerialListing,
  RegistrationDisplayEntry,
  RegistrationListing,
} from "../model";
import Network from "../network";
import { useWindowSize } from "../tools";
import RegistrationDetailsView from "../components/RegistrationDetailsView";
import AddIcon from "@mui/icons-material/Add";
import NewRegistrationDialog from "../components/NewRegistrationDialog";

function EditToolbar({ handleNewRegistration, ...props }) {
  return (
    <GridToolbarContainer>
      <GridToolbar sx={{ flexGrow: 1 }} />
      <Button startIcon={<AddIcon />} onClick={handleNewRegistration}>
        New Registration
      </Button>
    </GridToolbarContainer>
  );
}

function RegistrationsView() {
  const [, height] = useWindowSize();
  const hasBeenCalled = React.useRef(false);

  const [isLoading, setIsLoading] = React.useState(true);

  const [rows, setRows] = React.useState<RegistrationDisplayEntry[]>([]);

  const [selectedCode, setSelectedCode] = React.useState("");
  const [selectedRegistration, setSelectedRegistration] =
    React.useState<RegistrationListing | null>(null);

  const [newRegistrationDialogOpen, setNewRegistrationDialogOpen] =
    React.useState(false);

  const loadData = async () => {
    let resp = await Network.Get("registrations");
    if (resp.status === 200) {
      const registrations = resp.data as RegistrationListing[];
      resp = await Network.Get("licensekeys/rejoyce_serials");
      if (resp.status === 200) {
        const keysWithSerials = resp.data as LicenseKeyRejoyceSerialListing[];
        let registrationEntries: RegistrationDisplayEntry[] = [];

        registrations.forEach((registration) => {
          const rejoyce_serials = keysWithSerials
            .filter((elem) => elem.key === registration.license_key)
            .map((elem) => elem.serial_number);

          registrationEntries.push({
            id: registration.registration_id,
            license_key: registration.license_key,
            rejoyce_serial_numbers: rejoyce_serials.join(", "),
            company: registration.registration.organization,
            contact: registration.registration.contact_name,
            email: registration.registration.contact_email,
            phone: registration.registration.contact_phone,
            address: registration.registration.address,
          });
        });

        setRows(registrationEntries);
      }
    }
  };

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    await loadData();

    setIsLoading(false);
  };

  React.useEffect(() => {
    constructor();
  });

  const handleNewRegistration = () => {
    setNewRegistrationDialogOpen(true);
  };
  const handleNewRegistrationClose = async (updated: boolean) => {
    if (updated) loadData();
    setNewRegistrationDialogOpen(false);
  };

  const getRegistrationCode = async (row: RegistrationDisplayEntry) => {
    const resp = await Network.Get("registrations/code/" + row.id);
    if (resp.status === 200) {
      setSelectedCode(resp.data.code);
      setSelectedRegistration(resp.data.registration);
    }
  };

  const handleRegistrationDetailsGoBack = () => {
    setSelectedCode("");
    setSelectedRegistration(null);
  };

  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: "license_key", headerName: "License Key", width: 250 },
      {
        field: "rejoyce_serial_numbers",
        headerName: "ReJoyce Serial Numbers",
        width: 175,
      },
      { field: "company", headerName: "Company", flex: 1 },
      { field: "contact", headerName: "Contact", width: 150 },
      { field: "email", headerName: "Email", flex: 1 },
      { field: "phone", headerName: "Phone", width: 150 },
      { field: "address", headerName: "Address", flex: 1 },
      {
        field: "view_code",
        headerName: "Activation Code",
        minWidth: 150,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<VpnKeyIcon />}
            onClick={() => getRegistrationCode(params.row)}
          >
            Code
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Box sx={{ textAlign: "center" }}>
      <NewRegistrationDialog
        open={newRegistrationDialogOpen}
        handleClose={handleNewRegistrationClose}
        registrations={rows}
      />
      {selectedRegistration ? (
        <RegistrationDetailsView
          code={selectedCode}
          registration={selectedRegistration}
          goBack={handleRegistrationDetailsGoBack}
        />
      ) : (
        <DataGrid
          loading={isLoading}
          rows={rows}
          columns={columns}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: {
              handleNewRegistration,
            },
          }}
          sx={{
            height: height * 0.75,
            boxShadow: 3,
          }}
        />
      )}
    </Box>
  );
}

export default RegistrationsView;
