import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BallotIcon from "@mui/icons-material/Ballot";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import GridViewIcon from "@mui/icons-material/GridView";
import KeyIcon from "@mui/icons-material/Key";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import InfoIcon from "@mui/icons-material/Info";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import MoneyIcon from "@mui/icons-material/Money";
import GroupsIcon from "@mui/icons-material/Groups";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { MainTheme } from "../theme";
import { DrawerMenuItems, UserData, UserRole } from "../model";
import { Box, ListSubheader } from "@mui/material";

export const LeftDrawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: LeftDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: LeftDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function InitMenu(userData: UserData): DrawerMenuItems[] {
  switch (userData.user_role) {
    case UserRole.Admin:
      return [
        DrawerMenuItems.Dashboard,
        DrawerMenuItems.LicenseKeys,
        DrawerMenuItems.ReJoyceSerialNumbers,
        DrawerMenuItems.Registrations,
        //DrawerMenuItems.Licenses,
        DrawerMenuItems.Distributors,
        DrawerMenuItems.UserManagement,
        DrawerMenuItems.ActionLog,
        DrawerMenuItems.Settings,
      ];
    case UserRole.User:
      return [
        DrawerMenuItems.Dashboard,
        DrawerMenuItems.LicenseKeys,
        DrawerMenuItems.ReJoyceSerialNumbers,
        DrawerMenuItems.Registrations,
        //DrawerMenuItems.Licenses,
        DrawerMenuItems.Distributors,
      ];
    case UserRole.Distributor:
      return [
        DrawerMenuItems.Dashboard,
        DrawerMenuItems.LicenseKeys,
        DrawerMenuItems.ReJoyceSerialNumbers,
        DrawerMenuItems.DistributorUIDefaults,
      ];
  }
  return [];
}

function LeftDrawer({
  userData,
  open,
  handleDrawerClose,
  setSelectedView,
  ...props
}: any) {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [menuItems] = React.useState<DrawerMenuItems[]>(InitMenu(userData));

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <img src="./rehabtronics.svg" width="180px" alt="React Logo" />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menuItems.map((text: string, index: number) => (
          <Box key={text}>
            {/* Display subheaders here to avoid re-mapping menuItems for few times and keep correct indeses */}
            {open && menuItems[index] === DrawerMenuItems.Dashboard && (
              <ListSubheader>LICENSES</ListSubheader>
            )}
            {open && menuItems[index] === DrawerMenuItems.Distributors && (
              <ListSubheader>CONFIGURATION</ListSubheader>
            )}
            {open && menuItems[index] === DrawerMenuItems.UserManagement && (
              <ListSubheader>SETTINGS</ListSubheader>
            )}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={selectedIndex === index}
                onClick={() => {
                  setSelectedIndex(index);
                  setSelectedView(menuItems[index] as DrawerMenuItems);
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : "auto",
                    justifyContent: "center",
                    color: MainTheme.palette.secondary.main,
                  }}
                >
                  {menuItems[index] === DrawerMenuItems.Dashboard ? (
                    <GridViewIcon />
                  ) : menuItems[index] === DrawerMenuItems.LicenseKeys ? (
                    <KeyIcon />
                  ) : menuItems[index] ===
                    DrawerMenuItems.ReJoyceSerialNumbers ? (
                    <MoneyIcon />
                  ) : menuItems[index] === DrawerMenuItems.Licenses ? (
                    <BallotIcon />
                  ) : menuItems[index] === DrawerMenuItems.Distributors ? (
                    <LocalShippingIcon />
                  ) : menuItems[index] ===
                    DrawerMenuItems.DistributorUIDefaults ? (
                    <DocumentScannerIcon />
                  ) : menuItems[index] === DrawerMenuItems.Registrations ? (
                    <HowToRegIcon />
                  ) : menuItems[index] === DrawerMenuItems.Settings ? (
                    <InfoIcon />
                  ) : menuItems[index] === DrawerMenuItems.UserManagement ? (
                    <GroupsIcon />
                  ) : menuItems[index] === DrawerMenuItems.ActionLog ? (
                    <DocumentScannerIcon />
                  ) : (
                    <Brightness1Icon />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Box>
        ))}
      </List>
    </Drawer>
  );
}

export default LeftDrawer;
