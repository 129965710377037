import * as React from "react";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";
import Network from "../network";

function SettingsView() {
    const hasBeenCalled = React.useRef(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const [version, setVersion] = React.useState("");

    const constructor = async () => {
        if (hasBeenCalled.current) return;
        hasBeenCalled.current = true;

        let resp = await Network.Get("settings/about");
        if (resp.status === 200) {
            setVersion(resp.data.version);
        }

        setIsLoading(false);
    };

    React.useEffect(() => {
        constructor();
    });

    return (
        <Box>
            {!isLoading && (
                <Box>
                    <Typography variant="h5" sx={{mt: 2}}>
                        Version {version}
                    </Typography>
                    <Typography sx={{mt: 2}}>
                        This tool was created to facilitate the licensing and registration
                        requirements for the ReHab software developed at Rehabtronics, Inc.
                        You are currently using the "administrator" user interface, designed
                        for Rehabtronics employees to view and manage the licenses. This
                        application also provides an API interface which is consumed by the
                        installation programs as well as ReHab itself to facilitate license
                        file generation and registration.
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default SettingsView;
