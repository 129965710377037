import * as React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import Network from "../network";
import {
    DistributorDefaultsListing,
    DistributorLicenseFeature,
    DistributorListing,
    LicenseBodyParsed,
    LicenseFeatures,
    LicenseFeaturesDisplay,
    LicenseListing,
    NewLicenseFeature,
    ReJoyceSerialNumberListing,
} from "../model";
import {DatePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import {DataGrid, GridColumns, GridPreProcessEditCellProps, GridRowModel, GridSelectionModel,} from "@mui/x-data-grid";
import {LicenseDisplayAdapter} from "../licenseDisplayAdapter";

interface NewLicenseFeatureDisplay extends NewLicenseFeature {
    enabled: boolean;
}

interface NewLicenseForm {
    license_type: string;
    distributor_name: string;
    expires: string | null;
    options: string | null;
    features: NewLicenseFeatureDisplay[];
    rejoyce_serial: string | null;
}

interface EditLicenseForm {
    license_type: string;
    distributor_name: string;
    created_at: string;
    uuid: string;
    expires: string | null;
    options: string | null;
    features: NewLicenseFeatureDisplay[];
}

const columns: GridColumns = [
    {field: "name", headerName: "Feature", width: 180},
    {
        field: "expires",
        headerName: "Expires (double-click to set)",
        type: "date",
        width: 200,
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            return params.props.value;
        },
    },
    {field: "options", headerName: "Options", editable: true},
];

interface CreateEditLicenseDialogProps {
    open: boolean;
    closeDialog: any;
    licenseKey: string;
    licenseKeyDistributorName: string;
    item: LicenseListing | null;
}

function CreateEditLicenseDialog({
                                     open,
                                     closeDialog,
                                     licenseKey,
                                     licenseKeyDistributorName,
                                     item,
                                 }: CreateEditLicenseDialogProps) {
    const hasBeenCalled = React.useRef(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const [isEdit] = React.useState(item !== null);

    const [licenseType, setLicenseType] = React.useState("");

    const [licenseTypes, setLicenseTypes] = React.useState<
        LicenseFeatures[] | null
    >(null);

    const [serialNumberId, setSerialNumberId] = React.useState("");

    const [serialNumbers, setSerialNumbers] = React.useState<
        ReJoyceSerialNumberListing[]
    >([]);

    const [licenseFeatures, setLicenseFeatures] = React.useState<
        LicenseFeaturesDisplay[]
    >([]);

    const [selectionModel, setSelectionModel] =
        React.useState<GridSelectionModel>([]);

    const [distributorName, setDistributorName] = React.useState("");

    const [distributors, setDistributors] = React.useState<DistributorListing[]>(
        []
    );

    const [distributorListingAlias, setDistributorListingAlias] =
        React.useState("");

    const [distributorListings, setDistributorListings] = React.useState<
        DistributorDefaultsListing[]
    >([]);

    const [expiresDate, setExpiresDate] = React.useState<Moment | null>(null);

    const [options, setOptions] = React.useState("");

    const constructor = async () => {
        if (hasBeenCalled.current) return;
        hasBeenCalled.current = true;

        let licenseTypes: LicenseFeatures[] = [];
        let resp = await Network.Get("licenses");
        if (resp.status === 200) {
            licenseTypes = resp.data as LicenseFeatures[];
            setLicenseTypes(licenseTypes);
        }

        resp = await Network.Get("distributors");
        if (resp.status === 200) {
            const data = resp.data as DistributorListing[];
            setDistributors(data);

            if (licenseKeyDistributorName !== "-") {
                setDistributorName(licenseKeyDistributorName);
                resp = await Network.Get(
                    "distributor_defaults/" +
                    data.filter((elem) => elem.name === licenseKeyDistributorName)[0].id
                );
                if (resp.status === 200) {
                    setDistributorListings(resp.data);
                }
            } else if (licenseKeyDistributorName === "-" && isEdit) {
                setDistributorName((item!.license as LicenseBodyParsed).distributor);
                const resp = await Network.Get(
                    "distributor_defaults/" +
                    data.filter(
                        (elem) =>
                            elem.name === (item!.license as LicenseBodyParsed).distributor
                    )[0].id
                );
                if (resp.status === 200) {
                    setDistributorListings(resp.data);
                }
            }
        }

        if (isEdit) {
            resp = await Network.Get(
                "rejoyce_serial_numbers/by_license/" + item!.uuid
            );
            if (resp.status === 200) {
                const serialNumber = resp.data as ReJoyceSerialNumberListing;
                const serialNumbers: ReJoyceSerialNumberListing[] = [];
                serialNumbers.push(serialNumber);
                setSerialNumbers(serialNumbers);
                setSerialNumberId(serialNumber.id.toString());
            }
        } else {
            resp = await Network.Get("rejoyce_serial_numbers/unused");
            if (resp.status === 200) {
                const serialNumbers = resp.data as ReJoyceSerialNumberListing[];
                setSerialNumbers(serialNumbers);
            }
        }

        if (isEdit) {
            setLicenseType(item!.license_type);
            setExpiresDate(item!.expires ? moment(item!.expires) : null);
            const option = (item!.license as LicenseBodyParsed).options;
            setOptions(option ? option : "");

            const license = licenseTypes!.filter(
                (license) => license.key === item!.license_type
            )[0];

            let features: LicenseFeaturesDisplay[] = [];
            let selections: number[] = [];
            license.features.forEach((feature) => {
                const itemFeature: NewLicenseFeature | undefined = (
                    item!.license as LicenseBodyParsed
                ).features.find((elem) => elem.feature === feature);

                if (itemFeature !== undefined) selections.push(features.length);
                features.push({
                    id: features.length,
                    name: feature,
                    expires:
                        itemFeature !== undefined
                            ? itemFeature!.expires
                                ? new Date(
                                    itemFeature!.expires.includes('T')
                                        ? itemFeature!.expires
                                        : `${itemFeature!.expires}T00:00:00.000Z`
                                )
                                : null
                            : null,
                    options:
                        itemFeature !== undefined
                            ? itemFeature.options
                                ? itemFeature.options
                                : ""
                            : "",
                });
            });
            setSelectionModel(selections);
            setLicenseFeatures(features);
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        constructor();
    });

    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow = newRow as LicenseFeaturesDisplay;
        setLicenseFeatures(
            licenseFeatures.map((row) =>
                row.id === updatedRow.id ? updatedRow : row
            )
        );
        return newRow;
    };

    const createLicense = async () => {
        let features: NewLicenseFeatureDisplay[] = [];
        licenseFeatures.forEach((feature) => {
            features.push({
                feature: feature.name,
                expires: feature.expires
                    ? feature.expires.toISOString().split("T")[0]
                    : null,
                enabled: selectionModel.includes(feature.id),
                options: feature.options,
            });
        });

        let rejoyce_serial_json: string | null = null;

        if (serialNumberId !== "") {
            let serial = serialNumbers.filter(
                (element) => element.id === Number.parseInt(serialNumberId)
            )[0];
            rejoyce_serial_json = JSON.stringify(serial.serial_number);
        }

        let resp;

        if (isEdit) {
            const editLicenseForm: EditLicenseForm = {
                license_type: licenseType,
                distributor_name: distributorName,
                created_at: item!.created_at,
                uuid: item!.uuid,
                expires: expiresDate ? expiresDate!.format("YYYY-MM-DD") : null,
                options: options,
                features: features,
            };

            resp = await Network.Put("licenses/" + licenseKey, editLicenseForm);
        } else {
            const newLicenseForm: NewLicenseForm = {
                license_type: licenseType,
                distributor_name: distributorName,
                expires: expiresDate ? expiresDate!.format("YYYY-MM-DD") : null,
                options: options,
                features: features,
                rejoyce_serial: rejoyce_serial_json,
            };

            resp = await Network.Post("licenses/" + licenseKey, newLicenseForm);
        }
        if (resp.status === 200) {
            closeDialog(true);
        }
    };

    const handleLicenseTypeChange = async (event: SelectChangeEvent) => {
        setLicenseType(event.target.value as string);
        const license = licenseTypes!.filter(
            (license) => license.key === (event.target.value as string)
        )[0];

        let features: LicenseFeaturesDisplay[] = [];
        let selections: number[] = [];
        license.features.forEach((feature) => {
            selections.push(features.length);
            features.push({
                id: features.length,
                name: feature,
                expires: null,
                options: "",
            });
        });
        setSelectionModel(selections);
        setLicenseFeatures(features);

        let resp;
        if ((event.target.value as string) === "com.rehabtronics.rejoyce")
            resp = await Network.Get("rejoyce_serial_numbers/unused");
        else
            resp = await Network.Get("rejoyce_serial_numbers");
        if (resp.status === 200) {
            const serialNumbers = resp.data as ReJoyceSerialNumberListing[];
            setSerialNumbers(serialNumbers);
        }

        if (distributorName === "") setDistributorListingAlias("");
        else if ((event.target.value as string) === "com.rehabtronics.rehab")
            setDistributorListingAlias("ReHab Default");
        else if ((event.target.value as string) === "com.rehabtronics.rejoyce")
            setDistributorListingAlias("ReJoyce Default");
    };

    const handleSerialNumberChange = (event: SelectChangeEvent) => {
        setSerialNumberId(event.target.value as string);
    };

    const handleDistributorNameChange = async (event: SelectChangeEvent) => {
        setDistributorName(event.target.value as string);
        const resp = await Network.Get(
            "distributor_defaults/" +
            distributors.filter(
                (elem) => elem.name === (event.target.value as string)
            )[0].id
        );
        if (resp.status === 200) {
            setDistributorListings(resp.data);

            if (licenseType === "com.rehabtronics.rehab")
                setDistributorListingAlias("ReHab Default");
            else if (licenseType === "com.rehabtronics.rejoyce")
                setDistributorListingAlias("ReJoyce Default");
        }
    };

    const handleDistributorListingChange = (event: SelectChangeEvent) => {
        const alias = event.target.value as string;
        const distributorDefaults = distributorListings.filter(
            (elem) => elem.alias === alias
        )[0];
        const license = licenseTypes!.filter(
            (license) => license.key === distributorDefaults.license_defaults.key
        )[0];

        let features: LicenseFeaturesDisplay[] = [];
        let selections: number[] = [];
        license.features.forEach((feature) => {
            const defaultsFeature: DistributorLicenseFeature | undefined =
                distributorDefaults.license_defaults.features.find(
                    (elem) => elem.name === feature
                );

            if (defaultsFeature !== undefined) selections.push(features.length);

            features.push({
                id: features.length,
                name: feature,
                expires: null,
                options:
                    defaultsFeature !== undefined
                        ? defaultsFeature.options
                            ? defaultsFeature.options
                            : ""
                        : "",
            });
        });
        setSelectionModel(selections);
        setLicenseFeatures(features);
        setDistributorListingAlias(alias);
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick") return;
                closeDialog(false);
            }}
        >
            <DialogTitle>{isEdit ? "Edit License" : "New License"}</DialogTitle>
            {!isLoading && (
                <DialogContent>
                    <FormControl fullWidth size="small" sx={{mt: 1}} disabled={isEdit}>
                        <InputLabel id="license-type-label">License Type</InputLabel>
                        <Select
                            labelId="license-type-label"
                            id="license-type"
                            value={licenseType}
                            label="License Type"
                            onChange={handleLicenseTypeChange}
                        >
                            {licenseTypes?.map((type) => (
                                <MenuItem key={type.key} value={type.key}>
                                    {LicenseDisplayAdapter.GetDisplayName(type.key)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        fullWidth
                        size="small"
                        sx={{mt: 3}}
                        disabled={isEdit}
                    >
                        <InputLabel id="serial-number-label">
                            ReJoyce Serial Number
                        </InputLabel>
                        <Select
                            labelId="serial-number-label"
                            id="serial-number"
                            value={serialNumberId}
                            label="ReJoyce Serial Number"
                            onChange={handleSerialNumberChange}
                        >
                            {serialNumbers.map((element) => (
                                <MenuItem key={element.id} value={element.id}>
                                    {element.serial_number.serial_number}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth size="small" sx={{mt: 3}}>
                        <InputLabel id="distributor-name-label">
                            Distributor Name
                        </InputLabel>
                        <Select
                            labelId="distributor-name-label"
                            id="distributor-name"
                            value={distributorName}
                            label="Distributor Name"
                            onChange={handleDistributorNameChange}
                        >
                            {distributors.map((elem) => (
                                <MenuItem key={elem.id} value={elem.name}>
                                    {elem.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {licenseType !== "" && (
                        <FormControl fullWidth size="small" sx={{mt: 3}}>
                            <InputLabel id="license-template-label">
                                License Template
                            </InputLabel>
                            <Select
                                labelId="license-template-label"
                                id="license-template"
                                value={distributorListingAlias}
                                label="License Template"
                                onChange={handleDistributorListingChange}
                            >
                                {distributorListings
                                    .filter((elem) => licenseType === elem.license_defaults.key)
                                    .map((elem) => (
                                        <MenuItem key={elem.id} value={elem.alias}>
                                            {elem.alias}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    <Box sx={{mt: 2, ml: 1}}>
                        <DatePicker
                            label="Expires"
                            value={expiresDate}
                            minDate={moment()}
                            onChange={(newValue) => {
                                setExpiresDate(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{mr: 2}}
                                    variant="standard"
                                    size="small"
                                    {...params}
                                    helperText={null}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{mt: 3}}>
                        <TextField
                            fullWidth
                            label="Options"
                            multiline
                            rows={6}
                            value={options}
                            onChange={(event: any) => {
                                setOptions(event.target.value);
                            }}
                        />
                    </Box>
                    {licenseFeatures.length > 0 && (
                        <Box sx={{height: 400}}>
                            <DataGrid
                                rows={licenseFeatures}
                                columns={columns}
                                processRowUpdate={processRowUpdate}
                                experimentalFeatures={{newEditingApi: true}}
                                checkboxSelection
                                disableSelectionOnClick
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                            />
                        </Box>
                    )}
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={() => closeDialog(false)} color="secondary" fullWidth>
                    Cancel
                </Button>
                <Button
                    disabled={
                        licenseType === "" ||
                        distributorName === "" ||
                        serialNumberId === ""
                    }
                    onClick={createLicense}
                    variant="contained"
                    fullWidth
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateEditLicenseDialog;
