import * as React from "react";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { RegistrationListing } from "../model";
import { MainTheme } from "../theme";
import Network from "../network";

export interface RegistrationDetialsViewProps {
  registration: RegistrationListing;
  code: string;
  goBack: any;
}

function RegistrationDetailsView({
  registration,
  code,
  goBack,
  ...props
}: RegistrationDetialsViewProps) {
  const downloadCode = async () => {
    const resp = await Network.Download(
      "registrations/code/" + registration.registration_id + "/download"
    );
    if (resp.status === 200) {
      const href = URL.createObjectURL(resp.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "registration.act");
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  return (
    <Box>
      <Box sx={{ flexDirection: "row", display: "flex" }}>
        <IconButton color="primary" onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{ flex: 1, textAlign: "center" }}
          variant="h4"
          color="primary"
        >
          Activation Code
        </Typography>
      </Box>
      <Paper sx={{ p: 2 }}>
        <Typography textAlign="left">
          <b>License key:&nbsp;</b>
          {registration.license_key}
        </Typography>
        <Typography textAlign="left">
          <b>Organization:&nbsp;</b>
          {registration.registration.organization}
        </Typography>
        <Typography textAlign="left">
          <b>Contact name:&nbsp;</b>
          {registration.registration.contact_name}
        </Typography>
        <Typography textAlign="left">
          <b>Contact email:&nbsp;</b>
          {registration.registration.contact_email}
        </Typography>
        <Typography textAlign="left">
          <b>Contact phone:&nbsp;</b>
          {registration.registration.contact_phone}
        </Typography>
        <Typography textAlign="left">
          <b>Address:&nbsp;</b>
          {registration.registration.address}
        </Typography>
        <Typography textAlign="left">
          <b>City:&nbsp;</b>
          {registration.registration.city}
        </Typography>
        <Typography textAlign="left">
          <b>Region:&nbsp;</b>
          {registration.registration.region}
        </Typography>
        <Typography textAlign="left">
          <b>Country:&nbsp;</b>
          {registration.registration.country}
        </Typography>
        <Typography textAlign="left">
          <b>Activation Code Signature:</b>
        </Typography>
        <Typography
          sx={{
            backgroundColor: MainTheme.palette.grey[100],
            p: 5,
            fontSize: 14,
            fontFamily: "Monospace",
          }}
        >
          {code}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            startIcon={<CloudDownloadIcon />}
            color="secondary"
            onClick={downloadCode}
          >
            Download
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default RegistrationDetailsView;
