import * as React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import {
  DistributorDefaultsListing,
  DistributorListing,
  LicenseFeatures,
} from "../model";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  DeleteLicenseDefaultsDialog,
  NewEditLicenseDefaultsDialog,
} from "./NewEditDeleteLicenseDefaultsDialog";
import Network from "../network";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { LicenseDisplayAdapter } from "../licenseDisplayAdapter";

export interface DistributorItemViewProps {
  distributor: DistributorListing;
  goBack: any | null;
}

function DistributorItemView({
  distributor,
  goBack,
  ...props
}: DistributorItemViewProps) {
  const hasBeenCalled = React.useRef(false);

  const [newLicenseDefaultsDialogOpen, setNewLicenseDefaultsDialogOpen] =
    React.useState(false);

  const handleNewLicenseDefaultsDialogClose = (updated: boolean) => {
    if (updated) loadDistributorInfo();

    setNewLicenseDefaultsDialogOpen(false);
    setSelectedLicenseDefaults(null);
  };

  const [selectedLicenseDefaults, setSelectedLicenseDefaults] =
    React.useState<DistributorDefaultsListing | null>(null);

  const handleEditLicenseDefaultsDialogOpen = (
    item: DistributorDefaultsListing
  ) => {
    setSelectedLicenseDefaults(item);
    setNewLicenseDefaultsDialogOpen(true);
  };

  const [deleteLicenseDefaultsDialogOpen, setDeleteLicenseDefaultsDialogOpen] =
    React.useState(false);

  const handleDeleteLicenseDefaultsDialogOpen = (
    item: DistributorDefaultsListing
  ) => {
    setSelectedLicenseDefaults(item);
    setDeleteLicenseDefaultsDialogOpen(true);
  };

  const handleDeleteLicenseDefaultsDialogClose = (updated: boolean) => {
    if (updated) loadDistributorInfo();

    setDeleteLicenseDefaultsDialogOpen(false);
    setSelectedLicenseDefaults(null);
  };

  const [distributorDefaults, setDistributorDefaults] = React.useState<
    DistributorDefaultsListing[]
  >([]);

  const [rehabDefaultPresent, setRehabDefaultPresent] = React.useState(false);

  const [licenses, setLicenses] = React.useState<LicenseFeatures[]>([]);

  const loadDistributorInfo = async () => {
    const resp = await Network.Get("distributor_defaults/" + distributor.id);
    if (resp.status === 200) {
      const data = resp.data as DistributorDefaultsListing[];
      setDistributorDefaults(data);
      setRehabDefaultPresent(
        data.filter(
          (elem) => elem.license_defaults.key === "com.rehabtronics.rehab"
        ).length > 0
      );
    }
  };

  const loadLicenses = async () => {
    const resp = await Network.Get("licenses");
    if (resp.status === 200) {
      const data = resp.data as LicenseFeatures[];
      setLicenses(data);
    }
  };

  const constructor = () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    loadLicenses();
    loadDistributorInfo();
  };

  React.useEffect(() => {
    constructor();
  });

  return (
    <Box>
      {newLicenseDefaultsDialogOpen && (
        <NewEditLicenseDefaultsDialog
          open={newLicenseDefaultsDialogOpen}
          closeDialog={handleNewLicenseDefaultsDialogClose}
          distributor_id={distributor.id}
          rehab_default_present={rehabDefaultPresent}
          editItem={selectedLicenseDefaults}
        />
      )}
      {deleteLicenseDefaultsDialogOpen && (
        <DeleteLicenseDefaultsDialog
          open={deleteLicenseDefaultsDialogOpen}
          handleClose={handleDeleteLicenseDefaultsDialogClose}
          deleteItem={selectedLicenseDefaults!}
        />
      )}
      <Box sx={{ flexDirection: "row", display: "flex", mb: 3 }}>
        {goBack !== null && (
          <IconButton color="primary" onClick={goBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          sx={{ flex: 1, textAlign: "center" }}
          variant="h4"
          color="primary"
        >
          {distributor.name}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setNewLicenseDefaultsDialogOpen(true)}
        >
          New License Template
        </Button>
      </Box>
      {distributorDefaults.map((element) => (
        <Accordion key={element.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {element.alias}
              </Typography>
              <ul>
                <li>
                  <b>Type: </b>
                  {LicenseDisplayAdapter.GetDisplayName(
                    element.license_defaults.key
                  )}
                </li>
                <li>
                  <b>Version: </b>
                  {element.license_defaults.license_version}
                </li>
                <li>
                  <b>Options: </b>
                  {element.license_defaults.options
                    ? element.license_defaults.options
                    : "-"}
                </li>
              </ul>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Enabled</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Feature Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licenses
                    .filter((elem) => elem.key === element.license_defaults.key)
                    .map((license) =>
                      license.features.map((featureName) => (
                        <TableRow key={featureName}>
                          <TableCell>
                            {element.license_defaults.features.find(
                              (feature) => feature.name === featureName
                            ) !== undefined ? (
                              <CheckIcon />
                            ) : (
                              <CloseIcon sx={{ color: "red" }} />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {featureName}
                          </TableCell>
                          <TableCell>
                            {element.license_defaults.features
                              .filter((feature) => feature.name === featureName)
                              .map((feature) =>
                                feature.options ? feature.options : "-"
                              )}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              </Table>
            </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Box>
                    <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      color="warning"
                      onClick={() =>
                        handleEditLicenseDefaultsDialogOpen(element)
                      }
                    >
                      Edit License Template
                    </Button>
                    {element.alias !== "ReHab Default" &&
                      element.alias !== "ReJoyce Default" && (
                    <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      color="error"
                      onClick={() =>
                        handleDeleteLicenseDefaultsDialogOpen(element)
                      }
                    >
                      Delete License Template
                    </Button>)}
                  </Box>
                </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default DistributorItemView;
