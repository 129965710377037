import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { LicenseDisplayAdapter } from "../licenseDisplayAdapter";
import { LicenseFeatures } from "../model";
import Network from "../network";
import { useWindowSize } from "../tools";

interface LicenseDisplayEntry {
  id: number;
  key: string;
  featureName: string;
}

const columns: GridColDef[] = [
  { field: "key", headerName: "License", flex: 1 },
  { field: "featureName", headerName: "Features", flex: 1 },
];

function LicensesView() {
  const [, height] = useWindowSize();
  const hasBeenCalled = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [rows, setRows] = React.useState<LicenseDisplayEntry[] | null>(null);

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const resp = await Network.Get("licenses");
    if (resp.status === 200) {
      const licenses = resp.data as LicenseFeatures[];

      let counter = 0;
      let licensesDisplay: LicenseDisplayEntry[] = [];
      licenses.forEach((element) => {
        licensesDisplay.push({
          id: counter++,
          key: LicenseDisplayAdapter.GetDisplayName(element.key),
          featureName: "",
        });
        element.features.forEach((feature) => {
          licensesDisplay.push({
            id: counter++,
            key: "",
            featureName: feature,
          });
        });
      });
      setRows(licensesDisplay);
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    constructor();
  });

  return (
    <Box sx={{ textAlign: "center" }}>
      {rows ? (
        <DataGrid
          loading={isLoading}
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          sx={{
            height: height * 0.75,
            boxShadow: 3,
          }}
        />
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}

export default LicensesView;
