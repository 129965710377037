export enum UserRole {
  Admin = "Admin",
  User = "User",
  Distributor = "Distributor",
}

export interface UserData {
  id: string;
  name: string;
  email: string;
  user_role: UserRole;
  distributor_id: number | null;
}

export interface UserDataEx extends UserData {
  disabled: number;
  created_at: string;
  modified_at: string | null;
}

export interface UserDataDisplayEntry {
  id: string;
  name: string;
  email: string;
  user_role: UserRole;
  user_role_display: string;
  distributor: string;
  distributor_id: number | null;
  disabled: string;
  created_at: string;
  modified_at: string;
}

export enum DrawerMenuItems {
  Dashboard = "Dashboard",
  LicenseKeys = "License Keys",
  ReJoyceSerialNumbers = "ReJoyce Serial Numbers",
  Registrations = "Registrations",
  Licenses = "Licenses",
  Distributors = "Distributors",
  DistributorUIDefaults = "License Templates",
  UserManagement = "User Management",
  Settings = "About",
  ActionLog = "Action Log",
}

export interface DistributorLicenseFeature {
  name: string;
  expires: string | null;
  options: string | null;
}

export interface DistributorLicense {
  key: string;
  expires: string | null;
  license_version: number;
  options: string | null;
  features: DistributorLicenseFeature[];
}

export interface DistributorListing {
  id: number;
  name: string;
}

export interface DistributorDefaultsListing {
  id: number;
  distributor_id: number;
  alias: string;
  license_defaults: DistributorLicense;
}

export interface StoreStats {
  num_license_keys: number;
  num_licenses: number;
  num_registrations: number;
}

export interface LicenseKeyListing {
  key: string;
  distributor_id: string | null;
  distributor: string | null;
  created_at: string;
  modified_at: string | null;
}

export interface LicenseKeyListingDisplay {
  id: number;
  key: string;
  rejoyce_serial_numbers: string;
  distributor: string;
  created_at: string;
  modified_at: string;
  view_license?: string;
}

export interface LicenseKeyRejoyceSerialListing {
  key: string;
  serial_number: string;
}

export interface NewLicenseFeature {
  feature: string;
  expires: string | null;
  options: string | null;
}

export interface LicenseBodyParsed {
  key: string;
  type: string;
  uuid: string;
  created_at: Date;
  license_version: number;
  distributor: string;
  options: string | null;
  expires: string | null;
  features: NewLicenseFeature[];
}

export interface LicenseListing {
  license: string | LicenseBodyParsed;
  uuid: string;
  license_type: string;
  revoked: boolean;
  created_at: string;
  expires: string | null;
}

export interface LicenseFeatures {
  key: string;
  features: string[];
}

export interface LicenseFeaturesDisplay {
  id: number;
  name: string;
  expires: Date | null;
  options: string;
}

export interface ReJoyceSerialNumber {
  version: string | null;
  serial_number: string;
  distributor_id: number;
  license_defaults: DistributorLicense[];
}

export interface ReJoyceSerialNumberListing {
  id: number;
  serial_number: ReJoyceSerialNumber;
  distributor_name: string;
  used: number;
  total_copies: number;
}

export interface RejoyceSerialNumberDisplayEntry {
  id: string;
  version: string;
  serial_number: string;
  distributor: string;
  distributor_id: number;
  license_defaults: string;
  expires: string;
  used: number;
  total_copies: number;
  available_assigned: string;
}

export interface Registration {
  organization: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  address: string;
  city: string;
  region: string;
  country: string;
}

export interface RegistrationListing {
  registration_id: string;
  license_key: string;
  registration: Registration;
}

export interface RegistrationDisplayEntry {
  id: string;
  license_key: string;
  rejoyce_serial_numbers: string;
  company: string;
  contact: string;
  email: string;
  phone: string;
  address: string;
  view_code?: string;
}
