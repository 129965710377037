import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as StringValidators from "../stringValidators";
import ValidatedTextField from "./ValidatedTextField";
import Network from "../network";
import {DistributorListing, UserDataDisplayEntry, UserRole} from "../model";
import {
    Box,
    DialogActions,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import {UserRoleDisplayAdapter} from "../userRoleDisplayAdapter";
import {MainTheme} from "../theme";

export interface AddEditUserDialogProps {
    open: boolean;
    distributors: DistributorListing[];
    handleClose: any;
    editItem: UserDataDisplayEntry | null;
}

function AddEditUserDialog({
                               open,
                               distributors,
                               handleClose,
                               editItem,
                           }: AddEditUserDialogProps) {
    const [isEdit] = React.useState(editItem !== null);

    const [email, setEmail] = React.useState({
        value: editItem ? editItem!.email : "",
        error: "",
    });

    const [name, setName] = React.useState({
        value: editItem ? editItem!.name : "",
        error: "",
    });

    const [userRole, setUserRole] = React.useState(
        editItem ? editItem!.user_role : ""
    );

    const [userRoles] = React.useState<UserRole[]>([
        UserRole.Admin,
        UserRole.User,
        UserRole.Distributor,
    ]);

    const [distributorName, setDistributorName] = React.useState(
        editItem && editItem!.user_role === UserRole.Distributor
            ? editItem!.distributor
            : ""
    );

    const [newUserPassword, setNewUserPassword] = React.useState("");

    const [deleteUserConfirmationDialog, setDeleteUserConfirmationDialog] =
        React.useState(false);

    const handleDeleteUser = async () => {
        const resp = await Network.Get("users/delete/" + editItem!.id);
        if (resp.status === 200) {
            setDeleteUserConfirmationDialog(false);
            handleClose(true);
        }
    };

    const handleEnableUser = async () => {
        const resp = await Network.Get("users/enable/" + editItem!.id);
        if (resp.status === 200) {
            handleClose(true);
        }
    };

    const handleDistributorNameChange = (event: SelectChangeEvent) => {
        setDistributorName(event.target.value as string);
    };

    const handleUserRoleChange = (event: SelectChangeEvent) => {
        setUserRole(event.target.value as string);
    };

    const handleSubmit = async () => {
        const emailError = StringValidators.emailValidator(email.value);
        const nameError = StringValidators.nameValidator(name.value);

        if (emailError || nameError) {
            setEmail({...email, error: emailError});
            setName({...name, error: nameError});
            return;
        }

        let distributor_id: number | null = null;
        if (distributorName !== "") {
            distributor_id = distributors.filter(
                (elem) => elem.name === distributorName
            )[0].id;
        }

        let resp;
        const data = {
            name: name.value,
            email: email.value,
            role: userRole,
            distributor_id,
        };
        if (isEdit) {
            resp = await Network.Put(`/users/${Number.parseInt(editItem!.id)}`, data);
        } else {
            resp = await Network.Post("/users", data);
        }

        if (resp.status === 200) {
            if (isEdit) handleClose(true);
            else setNewUserPassword(resp.data);
        } else if (resp.status === 400) {
            setEmail({...email, error: "Email already registered"});
        }
    };

    return (
        <Box>
            {deleteUserConfirmationDialog && (
                <Dialog open={open} keepMounted onClose={() => handleClose(false)}>
                    <DialogTitle
                        sx={{
                            backgroundColor: MainTheme.palette.error.main,
                            color: MainTheme.palette.common.white,
                        }}
                    >
                        Delete User
                    </DialogTitle>
                    <DialogContent dividers>
                        {`Delete user ${editItem!.email} ?`}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setDeleteUserConfirmationDialog(false)}
                            color="secondary"
                            fullWidth
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDeleteUser}
                            variant="contained"
                            color="error"
                            fullWidth
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Dialog
                open={open}
                keepMounted
                onClose={(event, reason) => {
                    if (reason && reason === "backdropClick") return;
                    handleClose(false);
                }}
            >
                <DialogTitle>
                    {isEdit ? "Edit User" : "New User"}
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.primary.main,
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {newUserPassword === "" ? (
                        <Box>
                            {isEdit && editItem!.disabled === "Yes" && (
                                <Box sx={{display: "flex", flexDirection: "row", mb: 3}}>
                                    <Button
                                        sx={{m: "3px"}}
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        onClick={handleEnableUser}
                                    >
                                        Enable User
                                    </Button>
                                    <Button
                                        sx={{m: "3px"}}
                                        variant="contained"
                                        color="error"
                                        fullWidth
                                        onClick={() => setDeleteUserConfirmationDialog(true)}
                                    >
                                        Delete User
                                    </Button>
                                </Box>
                            )}
                            <ValidatedTextField
                                autoComplete="email"
                                label="Email Address"
                                value={email.value}
                                onChange={(event: any) => {
                                    setEmail({
                                        value: event.target.value,
                                        error: "",
                                    });
                                }}
                                errorText={email.error}
                            />
                            <ValidatedTextField
                                autoComplete="name"
                                label="Full Name"
                                value={name.value}
                                onChange={(event: any) => {
                                    setName({
                                        value: event.target.value,
                                        error: "",
                                    });
                                }}
                                errorText={name.error}
                            />
                            <FormControl fullWidth size="small" sx={{mt: 3}}>
                                <InputLabel id="user-role-label">Role</InputLabel>
                                <Select
                                    labelId="user-role-label"
                                    id="user-role"
                                    value={userRole}
                                    label="Role"
                                    onChange={handleUserRoleChange}
                                >
                                    {userRoles.map((elem, index) => (
                                        <MenuItem key={index} value={elem}>
                                            {UserRoleDisplayAdapter.GetDisplayName(elem)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {userRole === UserRole.Distributor && (
                                <FormControl fullWidth size="small" sx={{mt: 3}}>
                                    <InputLabel id="distributor-name-label">
                                        Distributor Name
                                    </InputLabel>
                                    <Select
                                        labelId="distributor-name-label"
                                        id="distributor-name"
                                        value={distributorName}
                                        label="Distributor Name"
                                        onChange={handleDistributorNameChange}
                                    >
                                        {distributors.map((elem) => (
                                            <MenuItem key={elem.id} value={elem.name}>
                                                {elem.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                    ) : (
                        <DialogContentText>
                            User created with password <b>{newUserPassword}</b>, please send
                            it to them! It will never be displayed again!
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    {newUserPassword === "" ? (
                        <Box sx={{display: "flex", flexDirection: "row", width: "100%"}}>
                            <Button
                                onClick={() => handleClose(false)}
                                type="button"
                                fullWidth
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                disabled={
                                    userRole === "" ||
                                    (userRole === UserRole.Distributor && distributorName === "")
                                }
                                type="button"
                                fullWidth
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Box>
                    ) : (
                        <Button
                            onClick={() => handleClose(true)}
                            type="button"
                            fullWidth
                            variant="contained"
                        >
                            Ok
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AddEditUserDialog;
