import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ThemeProvider } from "@mui/material/styles";
import { MainTheme } from "./theme";
import { DrawerMenuItems, UserData } from "./model";
import Network from "./network";
import { Box, CssBaseline } from "@mui/material";
import SignIn from "./components/SignIn";
import TopAppBar from "./components/TopAppBar";
import LeftDrawer, { DrawerHeader } from "./components/LeftDrawer";
import LicensesView from "./views/LicensesView";
import DistirbutorsView from "./views/DistributorsView";
import DashboardView from "./views/DashboardView";
import LicenseKeysView from "./views/LicenseKeysView";
import RegistrationsView from "./views/RegistrationsView";
import SettingsView from "./views/SettingsView";
import ActionLogView from "./views/ActionLogView";
import ReJoyceSerialNumbersView from "./views/ReJoyceSerialNumbersView";
import UserManagementView from "./views/UserManagementView";
import DistirbutorUIDefaultsView from "./views/DistributorUIDefaultsView";

function App() {
  const [userData, setUserData] = React.useState<UserData | null>(null);

  const [open, setOpen] = React.useState(false);
  const [selectedView, setSelectedView] = React.useState<DrawerMenuItems>(
    DrawerMenuItems.Dashboard
  );

  const hasBeenCalled = React.useRef(false);
  const [pageLoaded, setPageLoaded] = React.useState(false);

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const resp = await Network.Get("auth/userinfo");
    if (resp.status === 200) {
      const userData = resp.data as UserData;
      setUserData(userData);
    }
    setPageLoaded(true);
  };

  React.useEffect(() => {
    constructor();
  }, [userData]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={MainTheme}>
        {pageLoaded &&
          (userData ? (
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <TopAppBar
                open={open}
                handleDrawerOpen={() => setOpen(true)}
                text={selectedView.toString()}
                userData={userData}
                setUserData={setUserData}
                setSelectedView={setSelectedView}
              />
              <LeftDrawer
                userData={userData}
                open={open}
                handleDrawerClose={() => setOpen(false)}
                setSelectedView={setSelectedView}
              />
              <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <DrawerHeader />
                {selectedView === DrawerMenuItems.Dashboard ? (
                  <DashboardView userData={userData} />
                ) : selectedView === DrawerMenuItems.LicenseKeys ? (
                  <LicenseKeysView userData={userData} />
                ) : selectedView === DrawerMenuItems.ReJoyceSerialNumbers ? (
                  <ReJoyceSerialNumbersView userData={userData} />
                ) : selectedView === DrawerMenuItems.Registrations ? (
                  <RegistrationsView />
                ) : selectedView === DrawerMenuItems.Licenses ? (
                  <LicensesView />
                ) : selectedView === DrawerMenuItems.Distributors ? (
                  <DistirbutorsView />
                ) : selectedView === DrawerMenuItems.DistributorUIDefaults ? (
                  <DistirbutorUIDefaultsView />
                ) : selectedView === DrawerMenuItems.Settings ? (
                  <SettingsView />
                ) : selectedView === DrawerMenuItems.UserManagement ? (
                  <UserManagementView />
                ) : selectedView === DrawerMenuItems.ActionLog ? (
                  <ActionLogView />
                ) : null}
              </Box>
            </Box>
          ) : (
            <SignIn setUserData={setUserData} />
          ))}
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
