import * as React from "react";
import { Box } from "@mui/material";
import DistributorItemView from "../components/DistributorItemView";
import { DistributorListing } from "../model";
import Network from "../network";

function DistirbutorUIDefaultsView() {
  const hasBeenCalled = React.useRef(false);

  const [selectedDistributor, setSelectedDistributor] =
    React.useState<DistributorListing | null>(null);

  const reloadData = async () => {
    const resp = await Network.Get("distributors");
    if (resp.status === 200) {
      const distributors = resp.data as DistributorListing[];
      if (distributors.length > 0) {
        setSelectedDistributor(distributors[0]);
      }
    }
  };

  const constructor = () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    reloadData();
  };

  React.useEffect(() => {
    constructor();
  });

  return (
    <Box>
      {selectedDistributor && (
        <DistributorItemView distributor={selectedDistributor!} goBack={null} />
      )}
    </Box>
  );
}

export default DistirbutorUIDefaultsView;
