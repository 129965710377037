import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import * as React from "react";
import {
  LicenseBodyParsed,
  LicenseListing,
  ReJoyceSerialNumber,
  RejoyceSerialNumberDisplayEntry,
  ReJoyceSerialNumberListing,
  UserData,
  UserRole,
} from "../model";
import Network from "../network";
import { useWindowSize } from "../tools";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { MainTheme } from "../theme";
import AddEditRejoyceSerialNumberDialog from "../components/AddEditRejoyceSerialNumberDialog";
import RevokeMultipleLicensesDialog from "../components/RevokeMultipleLicensesDialog";

interface ConfirmationDialogArgs {
  resolve: () => Promise<void>;
  caption: string;
  question: string;
}

interface EditToolbarProps {
  handleAddSerialNumber: () => void;
  isDistributor: boolean;
}

function EditToolbar(props: EditToolbarProps) {
  const { handleAddSerialNumber, isDistributor } = props;

  return (
    <GridToolbarContainer>
      <GridToolbar sx={{ flex: 1 }} />
      {!isDistributor && (
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddSerialNumber}
        >
          Add ReJoyce Serial Number
        </Button>
      )}
    </GridToolbarContainer>
  );
}

interface ReJoyceSerialNumbersViewProps {
  userData: UserData;
}

function ReJoyceSerialNumbersView({ userData }: ReJoyceSerialNumbersViewProps) {
  const [, height] = useWindowSize();
  const hasBeenCalled = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [rows, setRows] = React.useState<RejoyceSerialNumberDisplayEntry[]>([]);

  const [addEditSerialNumberDialogOpen, setAddEditSerialNumberDialogOpen] =
    React.useState(false);

  const [editReJoyceSerialNumberItem, setEditReJoyceSerialNumberItem] =
    React.useState<RejoyceSerialNumberDisplayEntry | null>(null);

  const handleAddSerialNumberDialogClose = (updated: boolean) => {
    if (updated) loadData();

    setAddEditSerialNumberDialogOpen(false);
    setEditReJoyceSerialNumberItem(null);
  };

  const [confirmationDialogArgs, setConfirmationDialogArgs] =
    React.useState<ConfirmationDialogArgs | null>(null);

  const [revokeLicensesDialogOpen, setRevokeLicensesDialogOpen] =
    React.useState(false);
  const [revokeLicensesItem, setRevokeLicensesItem] =
    React.useState<RejoyceSerialNumberDisplayEntry | null>(null);

  const handleRevokeLicensesDialogClose = (updated: boolean) => {
    if (updated) loadData();

    setRevokeLicensesDialogOpen(false);
    setRevokeLicensesItem(null);
  };

  const handleEditClick = (id: GridRowId) => async () => {
    const entry = rows.filter((row) => row.id == id)[0];

    setEditReJoyceSerialNumberItem(entry);
    setAddEditSerialNumberDialogOpen(true);
  };

  const handleUnregisterClick = (id: GridRowId) => async () => {
    let rowToUpdate = rows.filter(
      (row) => row.id === id
    )[0] as RejoyceSerialNumberDisplayEntry;

    if (rowToUpdate.used === 0) return;

    setRevokeLicensesItem(rowToUpdate);
    setRevokeLicensesDialogOpen(true);
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const row = rows.filter(
      (row) => row.id === id
    )[0] as RejoyceSerialNumberDisplayEntry;

    if (row.used !== 0) return;

    setConfirmationDialogArgs({
      caption: "Delete",
      question: `Do you want to delete ReJoyce Serial Number ${row.version} ${row.serial_number}?`,
      resolve: async () => {
        let serial: ReJoyceSerialNumber = {
          version: row.version,
          serial_number: row.serial_number,
          distributor_id: row.distributor_id,
          license_defaults: JSON.parse(row.license_defaults),
        };
        const resp = await Network.Post(
          "rejoyce_serial_numbers/delete/" + row.id,
          serial
        );
        if (resp.status === 200) {
          setRows(rows.filter((row) => row.id !== id));
        }
      },
    });
  };

  const loadData = async () => {
    const resp = await Network.Get("rejoyce_serial_numbers");
    if (resp.status === 200) {
      const serialNumbers = resp.data as ReJoyceSerialNumberListing[];

      let serialNumbersDisplay: RejoyceSerialNumberDisplayEntry[] = [];
      serialNumbers.forEach((element) => {
        serialNumbersDisplay.push({
          id: element.id.toString(),
          version: element.serial_number.version
            ? element.serial_number.version
            : "-",
          serial_number: element.serial_number.serial_number,
          distributor: element.distributor_name,
          distributor_id: element.serial_number.distributor_id,
          license_defaults: JSON.stringify(
            element.serial_number.license_defaults
          ),
          expires: "-",
          total_copies: element.total_copies,
          used: element.used,
          available_assigned: `${element.used}/${element.total_copies}`,
        });
      });
      setRows(serialNumbersDisplay);

      calculateExpirationTimes(serialNumbersDisplay);
    }
  };

  const updateExpiryDate = (
    originalDate: string | null,
    newDate: string | null
  ): string | null => {
    if (newDate === null) {
      return originalDate;
    }

    if (originalDate === null) {
      return newDate;
    }

    if (new Date(originalDate + "T00:00:00") > new Date(newDate + "T00:00:00"))
      return newDate;
    else return originalDate;
  };

  const calculateExpirationTimes = async (
    rows: RejoyceSerialNumberDisplayEntry[]
  ) => {
    for (let i = 0; i < rows.length; ++i)
      new Promise(async (resolve, reject) => {
        const resp = await Network.Get(
          "rejoyce_serial_numbers/licenses/" + rows[i].id
        );

        if (resp.status === 200) {
          const data = resp.data as LicenseListing[];
          data.forEach(
            (elem) => (elem.license = JSON.parse(elem.license as string))
          );
          let date: string | null = null;

          for (let j = 0; j < data.length; ++j) {
            date = updateExpiryDate(date, data[j].expires);
            for (
              let h = 0;
              h < (data[j].license as LicenseBodyParsed).features.length;
              ++h
            ) {
              date = updateExpiryDate(
                date,
                (data[j].license as LicenseBodyParsed).features[h].expires
              );
            }
          }

          let dateStr = date ? date : "-";
          setRows(
            rows.map((row) => {
              if (row.id === rows[i].id) row.expires = dateStr;
              return row;
            })
          );
        }
        resolve(null);
      });
  };

  const constructor = () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    loadData();

    setIsLoading(false);
  };

  React.useEffect(() => {
    constructor();
  });

  const columns: GridColumns = [
    {
      field: "version",
      headerName: "Version",
      width: 70,
    },
    {
      field: "serial_number",
      headerName: "Serial Number",
      width: 120,
    },
    { field: "distributor", headerName: "Distributor", flex: 1 },
    { field: "expires", headerName: "Expires", width: 140 },
    {
      field: "available_assigned",
      headerName: "#Used/#Available",
      width: 160,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 130,
      cellClassName: "actions",
      getActions: ({ id }) => {
        let elements = [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit Serial Number">
                <EditIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Revoke License">
                <ReplayCircleFilledIcon />
              </Tooltip>
            }
            label="Unregister"
            className="textPrimary"
            onClick={handleUnregisterClick(id)}
            color="inherit"
          />,
        ];
        if (userData.user_role !== UserRole.Distributor)
          elements.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete Serial Number">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          );
        return elements;
      },
    },
  ];

  const handleCancel = () => {
    setConfirmationDialogArgs(null);
  };

  const handleYes = async () => {
    const { resolve } = confirmationDialogArgs!;
    try {
      resolve();
      setConfirmationDialogArgs(null);
    } catch (error) {
      setConfirmationDialogArgs(null);
    }
  };

  const renderConfirmDialog = () => {
    if (!confirmationDialogArgs) {
      return null;
    }
    const { caption, question } = confirmationDialogArgs;
    return (
      <Dialog open={!!confirmationDialogArgs}>
        <DialogTitle
          sx={{
            backgroundColor: MainTheme.palette.error.main,
            color: MainTheme.palette.common.white,
          }}
        >
          {caption}
        </DialogTitle>
        <DialogContent dividers>{question}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary" fullWidth>
            Cancel
          </Button>
          <Button
            onClick={handleYes}
            variant="contained"
            color="error"
            fullWidth
          >
            {caption}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      {revokeLicensesDialogOpen && (
        <RevokeMultipleLicensesDialog
          open={revokeLicensesDialogOpen}
          closeDialog={handleRevokeLicensesDialogClose}
          item={revokeLicensesItem!}
        />
      )}
      {addEditSerialNumberDialogOpen && (
        <AddEditRejoyceSerialNumberDialog
          open={addEditSerialNumberDialogOpen}
          handleClose={handleAddSerialNumberDialogClose}
          editItem={editReJoyceSerialNumberItem}
        />
      )}
      {renderConfirmDialog()}
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: {
            handleAddSerialNumber: () => setAddEditSerialNumberDialogOpen(true),
            isDistributor: userData.user_role === UserRole.Distributor,
          },
        }}
        sx={{
          height: height * 0.75,
          boxShadow: 3,
        }}
      />
    </Box>
  );
}

export default ReJoyceSerialNumbersView;
