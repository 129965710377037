import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import {DataGrid, GridColDef, GridToolbar, GridToolbarContainer,} from "@mui/x-data-grid";
import BookIcon from "@mui/icons-material/Book";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import {
    DistributorListing,
    LicenseKeyListing,
    LicenseKeyListingDisplay,
    LicenseKeyRejoyceSerialListing,
    UserRole,
} from "../model";
import Network from "../network";
import {useWindowSize} from "../tools";
import ValidatedTextField from "../components/ValidatedTextField";
import * as StringValidators from "../stringValidators";
import LicenseItemView from "../components/LicenseItemView";

function EditToolbar({handleNewLicenseKey, buttonVisible, ...props}) {
    return (
        <GridToolbarContainer>
            <GridToolbar sx={{flexGrow: 1}}/>
            {buttonVisible && (
                <Button startIcon={<AddIcon/>} onClick={handleNewLicenseKey}>
                    New License Key
                </Button>
            )}
        </GridToolbarContainer>
    );
}

function LicenseKeysView({userData, ...props}) {
    const [, height] = useWindowSize();
    const hasBeenCalled = React.useRef(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const [selectedLicenseKey, setSelectedLicenseKey] =
        React.useState<LicenseKeyListingDisplay | null>(null);

    const [newLicenseKeyDialogOpen, setNewLicenseKeyDialogOpen] =
        React.useState(false);

    const [newLicenseKey, setNewLicenseKey] = React.useState({
        value: "",
        error: "",
    });

    const [distributor, setDistributor] = React.useState<DistributorListing>({
        id: -1,
        name: "",
    });

    const [distributors, setDistributors] = React.useState<DistributorListing[]>(
        []
    );

    const handleDistributorNameChange = (event: SelectChangeEvent) => {
        const distributor_id = Number.parseInt(event.target.value as string);
        setDistributor(
            distributors.filter((elem) => elem.id === distributor_id)[0]
        );
    };

    const [rows, setRows] = React.useState<LicenseKeyListingDisplay[] | null>(
        null
    );

    const loadKeys = async () => {
        let resp = await Network.Get("licensekeys");
        if (resp.status === 200) {
            const licenseKeys = resp.data as LicenseKeyListing[];

            resp = await Network.Get("distributors");
            if (resp.status === 200) {
                setDistributors(resp.data as DistributorListing[]);
            }

            resp = await Network.Get("licensekeys/rejoyce_serials");
            if (resp.status === 200) {
                const keysWithSerials = resp.data as LicenseKeyRejoyceSerialListing[];

                let counter = 0;
                let rows: LicenseKeyListingDisplay[] = [];
                licenseKeys.forEach((entry) => {
                    const created_at = new Date(entry.created_at);
                    const rejoyce_serials = keysWithSerials
                        .filter((elem) => elem.key === entry.key)
                        .map((elem) => elem.serial_number);

                    rows.push({
                        id: counter++,
                        key: entry.key,
                        rejoyce_serial_numbers: rejoyce_serials.join(", "),
                        distributor: entry.distributor ? entry.distributor : "-",
                        created_at: `${created_at.toDateString()} (${created_at.toLocaleTimeString()})`,
                        modified_at: entry.modified_at
                            ? `${new Date(entry.modified_at).toDateString()} (${new Date(
                                entry.modified_at
                            ).toLocaleTimeString()})`
                            : "-",
                    });
                });
                setRows(rows);
            }
        }
    };

    const constructor = async () => {
        if (hasBeenCalled.current) return;
        hasBeenCalled.current = true;

        loadKeys();

        setIsLoading(false);
    };

    React.useEffect(() => {
        constructor();
    });

    const handleNewLicenseKey = async () => {
        setNewLicenseKeyDialogOpen(true);
    };

    const createNewLicenseKey = async () => {
        const licenseKeyError = StringValidators.licenseKeyValidator(
            newLicenseKey.value
        );

        if (licenseKeyError) {
            setNewLicenseKey({...newLicenseKey, error: licenseKeyError});
            return;
        }

        const resp = await Network.Post("licensekeys", {
            key: newLicenseKey.value,
            distributor_id: distributor.id,
        });

        if (resp.status === 200) {
            loadKeys();
            setNewLicenseKeyDialogOpen(false);
        } else {
            setNewLicenseKey({
                ...newLicenseKey,
                error: `Failed to create license key: ${resp.data}`,
            });
        }
    };

    const columns: GridColDef[] = React.useMemo(
        () => [
            {field: "key", headerName: "License Key", flex: 1},
            {
                field: "rejoyce_serial_numbers",
                headerName: "ReJoyce Serial Numbers",
                flex: 1,
            },
            {field: "distributor", headerName: "Distributor", flex: 1},
            {field: "created_at", headerName: "Created At", flex: 1},
            {field: "modified_at", headerName: "Modified At", flex: 1},
            {
                field: "view_license",
                headerName: "View Licenses",
                minWidth: 150,
                renderCell: (params) => (
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<BookIcon/>}
                        onClick={() => setSelectedLicenseKey(params.row)}
                    >
                        Licenses
                    </Button>
                ),
            },
        ],
        []
    );

    return (
        <Box>
            <Dialog
                open={newLicenseKeyDialogOpen}
                onClose={(event, reason) => {
                    if (reason && reason === "backdropClick") return;
                    setNewLicenseKeyDialogOpen(false);
                }}
            >
                <DialogTitle>New License Key</DialogTitle>
                <DialogContent sx={{minWidth: 600}}>
                    <ValidatedTextField
                        label="License Key"
                        placeholder="abcd-efgh-ijkl-mnop-qrst-uvwx-2y"
                        value={newLicenseKey.value}
                        onChange={(event: any) => {
                            setNewLicenseKey({
                                value: event.target.value,
                                error: "",
                            });
                        }}
                        errorText={newLicenseKey.error}
                    />
                    <FormControl fullWidth size="small" sx={{mt: 3}}>
                        <InputLabel id="distributor-name-label">
                            Distributor Name
                        </InputLabel>
                        <Select
                            labelId="distributor-name-label"
                            id="distributor-name"
                            value={distributor.id === -1 ? "" : distributor.id.toString()}
                            label="Distributor Name"
                            onChange={handleDistributorNameChange}
                        >
                            {distributors.map((elem) => (
                                <MenuItem key={elem.id} value={elem.id}>
                                    {elem.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setNewLicenseKeyDialogOpen(false)}
                        fullWidth
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={createNewLicenseKey}
                        fullWidth
                        disabled={distributor.id === -1}
                        variant="contained"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {selectedLicenseKey !== null ? (
                <LicenseItemView
                    userData={userData}
                    licenseKey={selectedLicenseKey}
                    goBack={() => setSelectedLicenseKey(null)}
                />
            ) : rows ? (
                <DataGrid
                    loading={isLoading}
                    rows={rows}
                    columns={columns}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            handleNewLicenseKey,
                            buttonVisible: userData.user_role !== UserRole.Distributor,
                        },
                    }}
                    sx={{
                        height: height * 0.75,
                        boxShadow: 3,
                    }}
                />
            ) : (
                <>Loading...</>
            )}
        </Box>
    );
}

export default LicenseKeysView;
