import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as StringValidators from "../stringValidators";
import ValidatedTextField from "./ValidatedTextField";
import Network from "../network";
import {DistributorListing} from "../model";
import {MainTheme} from "../theme";
import {DialogActions} from "@mui/material";

export interface AddEditDistributorDialogProps {
    open: boolean;
    handleClose: any;
    editItem: DistributorListing | null;
}

export function AddEditDistributorDialog({
                                             open,
                                             handleClose,
                                             editItem,
                                         }: AddEditDistributorDialogProps) {
    const [isEdit] = React.useState(editItem !== null);

    const [name, setName] = React.useState({
        value: editItem ? editItem!.name : "",
        error: "",
    });

    const handleSubmit = async () => {
        const nameError = StringValidators.fieldNotEmptyValidator(name.value);

        if (nameError) {
            setName({...name, error: nameError});
            return;
        }

        if (isEdit) {
            const distributorUpdated: DistributorListing = {
                id: editItem!.id,
                name: name.value,
            };
            const resp = await Network.Put(
                "distributors",
                distributorUpdated
            );
            if (resp.status === 200) handleClose(true);
        } else {
            const resp = await Network.Post("distributors", {
                name: name.value,
            });
            if (resp.status === 200) handleClose(true);
            if (resp.status === 400) {
                setName({...name, error: resp.data});
                return;
            }
        }
    };

    return (
        <Dialog open={open} keepMounted onClose={() => handleClose(false)}>
            <DialogTitle>
                {isEdit ? "Edit Distributor" : "New Distributor"}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ValidatedTextField
                    autoComplete="name"
                    label="Distributor Name"
                    value={name.value}
                    onChange={(event: any) => {
                        setName({
                            value: event.target.value,
                            error: "",
                        });
                    }}
                    errorText={name.error}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose(false)}
                    type="button"
                    fullWidth
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    type="button"
                    fullWidth
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export interface DeleteDistributorDialogProps {
    open: boolean;
    handleClose: any;
    deleteItem: DistributorListing;
}

export function DeleteDistributorDialog({
                                            open,
                                            handleClose,
                                            deleteItem,
                                        }: DeleteDistributorDialogProps) {
    const [cantDelete, setCantDelete] = React.useState(false);
    const handleSubmit = async () => {
        const resp = await Network.Delete(`distributors/${deleteItem.id}`);
        if (resp.status === 200) handleClose(true);
        else if (resp.status === 400) {
            setCantDelete(true);
        }
    };

    return (
        <Dialog open={open} keepMounted onClose={() => handleClose(false)}>
            <DialogTitle
                sx={{
                    backgroundColor: MainTheme.palette.error.main,
                    color: MainTheme.palette.common.white,
                }}
            >
                Delete Distributor
            </DialogTitle>
            <DialogContent dividers>
                {cantDelete
                    ? "Cannot delete distributor with associated data"
                    : `Delete distributor ${deleteItem.name} and all related data?`}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="secondary" fullWidth>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="error"
                    disabled={cantDelete}
                    fullWidth
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
